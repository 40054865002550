@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "@vidstack/react/player/styles/default/theme.css";
@import "@vidstack/react/player/styles/default/layouts/audio.css";
@import "@vidstack/react/player/styles/default/layouts/video.css";

@layer base {
  :root {
    --background-color-dark: #121212;
    --text-color-dark: #ffffff;

    --color-black: 0 0 0;
    --color-white: 255 255 255;
    --color-transparent: 0 0 0;

    --color-purple-80: 25 9 58;
    --color-purple-70: 42 16 96;
    --color-purple-60: 68 25 154;
    --color-purple-50: 85 31 193;
    --color-purple-40: 116 71 204;
    --color-purple-30: 161 132 221;
    --color-purple-20: 213 199 240;
    --color-purple-10: 238 233 249;
    --color-purple-05: 246 243 253;

    --color-orange-80: 119 58 35;
    --color-orange-70: 160 79 45;
    --color-orange-60: 218 111 64;
    --color-orange-50: 243 122 65;
    --color-orange-40: 247 169 131;
    --color-orange-30: 250 202 179;
    --color-orange-20: 253 228 217;
    --color-orange-10: 254 244 240;

    --color-neutral-100: 26 27 29;
    --color-neutral-80: 25 9 58;
    --color-neutral-70: 62 56 81;
    --color-neutral-60: 93 88 108;
    --color-neutral-50: 129 126 140;
    --color-neutral-40: 190 188 196;
    --color-neutral-30: 211 210 215;
    --color-neutral-20: 229 228 231;
    --color-neutral-10: 242 242 243;
    --color-neutral-05: 250 250 250;

    --color-green-90: 29 93 93;
    --color-green-70: 49 138 78;
    --color-green-50: 70 202 107;
    --color-green-10: 228 248 233;
    --color-green-05: 35 211 102;

    --color-yellow-90: 104 73 26;
    --color-yellow-70: 177 131 51;
    --color-yellow-50: 239 180 67;
    --color-yellow-10: 253 241 218;

    --color-red-90: 100 19 44;
    --color-red-70: 154 40 71;
    --color-red-50: 208 55 92;
    --color-red-10: 250 231 236;

    --color-blue-50: 116 71 204;
    --color-blue-05: 60 89 151;
    --color-blue-04: 11 101 194;
    --color-blue-02: 29 161 241;

    --color-grey-10: 217 217 217;
    --color-grey-20: 225 225 225;

    /* custom theme */
    --color-slated-grey: 255 255 255;
    --color-slated-grey2: 255 255 255;
    --color-slated-purple: 246 243 253;
    --color-logo-chip: 255 255 255;
    --color-transparent-to-dark30: transparent;
    --color-neutral30-to-neutral30: 211 210 215;
    --color-neutral40-to-dark30: 190 188 196;
    --color-neutral20-to-black: 229 228 231;
    --color-black-to-dark10: 0 0 0;
    --color-white-to-dark00: 255 255 255;
    --color-white-to-dark10: 255 255 255;
    --color-white-to-dark20: 255 255 255;
    --color-white-to-dark30: 255 255 255;
    --color-neutral20-to-dark30: 229 228 231;
    --color-neutral20-to-purple40: 229 228 231;
    --color-neutral80-to-dark00: 25 9 58;
    --color-neutral40-to-dark20: 190 188 196;
    --color-field-input: 255 255 255;
    --color-field-input-disable: 229 228 231;
    --color-neutral100-to-purple50: 26 27 29;
    --color-white-to-white: 255 255 255;
    --color-black-to-black: 0 0 0;
    --color-black-to-white: 0 0 0;
    --color-placeholder: 190 188 196;
    --color-purple50-to-white: 85 31 193;
    --color-purple50-to-purple50: 85 31 193;
    --color-purple80-to-white: 25 9 58;
    --color-neutral50-to-white: 129 126 140;
    --color-neutral50-to-dark20: 129 126 140;
    --color-purple60-to-white: 68 25 154;
    --color-neutral20-to-dark40: 229 228 231;
    --color-red50-to-dark30: 208 55 92;
    --color-red50-to-dark10: 208 55 92;
    --color-red50-to-red50: 208 55 92;
    --color-green50-to-dark30: 70 202 107;
    --color-neutral40-to-dark00: 190 188 196;
    --color-neutral80-to-white: 25 9 58;
    --color-neutral20-to-dark50: 229 228 231;
    --color-white-to-dark50: 229 228 231;
    --color-white-to-dark80: 255 255 255;
    --color-white-to-neutral80: 255 255 255;
    --color-neutral80-to-dark10: 25 9 58;
    --color-neutral80-to-dark30: 25 9 58;
    --color-purple80-to-dark10: 25 9 58;
    --color-purple70-to-dark10: 42 16 96;
    --color-purple70-to-purple70: 42 16 96;
    --color-purple70-to-dark30: 42 16 96;
    --color-purple50-to-dark10: 85 31 193;
    --color-purple10-to-dark80: 238 233 249;
    --color-purple10-to-dark20: 238 233 249;
    --color-purple05-to-dark20: 246 243 253;
    --color-purple05-to-dark50: 246 243 253;
    --color-purple05-to-dark70: 246 243 253;
    --color-purple05-to-dark80: 246 243 253;
    --color-purple10-to-field-input-disable: 238 233 249;
    --color-purple10-to-dark10: 238 233 249;
    --color-purple10-to-dark50: 238 233 249;
    --color-purple10-to-dark50: 238 233 249;
    --color-purple20-to-dark80: 51 51 51;
    --color-neutral10-to-dark90: 242 242 243;
    /* --color-neutral80-to-dark00: 242 242 243; */
    /*  */
  }

  .dark {
    min-height: 100vh;
    background: black;

    --color-black: 255 255 255;
    --color-white: 0 0 0;
    --color-transparent: 0 0 0;

    --color-purple-80: 255 255 255;
    --color-purple-70: 255 255 255;
    --color-purple-60: 255 255 255;
    /* --color-purple-50: 178 178 178; */
    --color-purple-50: 116 71 204;
    --color-purple-40: 116 71 204;
    --color-purple-30: 161 132 221;
    --color-purple-20: 161 132 221;
    --color-purple-10: 41 41 41;
    --color-purple-05: 77 77 77;

    --color-orange-80: 255 255 255;
    --color-orange-70: 255 255 255;
    --color-orange-60: 255 255 255;
    --color-orange-50: 243 122 65;
    --color-orange-40: 255 255 255;
    --color-orange-30: 255 255 255;
    --color-orange-20: 255 255 255;
    --color-orange-10: 254 244 240;

    --color-neutral-100: 255 255 255;
    --color-neutral-80: 255 255 255;
    --color-neutral-70: 255 255 255;
    --color-neutral-60: 255 255 255;
    --color-neutral-50: 255 255 255;
    --color-neutral-40: 190 188 196;
    --color-neutral-30: 255 255 255;
    --color-neutral-20: 255 255 255;
    --color-neutral-10: 41 41 41;
    --color-neutral-05: 255 255 255;

    --color-green-90: 29 93 93;
    --color-green-70: 49 138 78;
    --color-green-50: 70 202 107;
    --color-green-10: 228 248 233;
    --color-green-05: 35 211 102;

    --color-yellow-90: 104 73 26;
    --color-yellow-70: 177 131 51;
    --color-yellow-50: 239 180 67;
    --color-yellow-10: 253 241 218;

    --color-red-90: 100 19 44;
    --color-red-70: 154 40 71;
    /* --color-red-50: 208 55 92; */
    --color-red-50: 255 128 158;
    --color-red-10: 250 231 236;

    --color-blue-50: 116 71 204;
    --color-blue-05: 60 89 151;
    --color-blue-04: 11 101 194;
    --color-blue-02: 29 161 241;

    --color-grey-10: 255 255 255;
    --color-grey-20: 255 255 255;

    /* custom theme */
    --color-slated-grey: 41 41 41;
    --color-slated-grey2: 41 41 41;
    --color-slated-purple: 41 41 41;
    --color-logo-chip: 255 255 255;
    --color-transparent-to-dark30: 77 77 77;
    --color-neutral40-to-dark30: 77 77 77;
    --color-neutral20-to-black: 0 0 0;
    --color-white-to-dark00: 0 0 0;
    --color-white-to-dark10: 26 26 26;
    --color-white-to-dark20: 51 51 51;
    --color-white-to-dark30: 77 77 77;
    --color-black-to-dark10: 26 26 26;

    --color-dark-00: 0 0 0;
    --color-dark-10: 26 26 26;
    --color-dark-20: 51 51 51;
    --color-dark-30: 77 77 77;
    --color-dark-40: 102 102 102;
    --color-dark-70: 62, 56, 81;
    --color-dark-80: 204 204 204;
    --color-dark-90: 26 26 26;

    --color-neutral20-to-dark30: 61 61 61;
    --color-neutral20-to-purple40: 116 71 204;
    --color-neutral80-to-dark00: 0 0 0;
    --color-neutral30-to-neutral30: 211 210 215;
    --color-neutral40-to-dark20: 51 51 51;
    --color-field-input: 0 0 0;
    --color-field-input-disable: 102 102 102;
    --color-neutral100-to-purple50: 85 31 193;
    --color-white-to-white: 255 255 255;
    --color-black-to-black: 0 0 0;
    --color-black-to-white: 255 255 255;
    --color-placeholder: 204 204 204;
    --color-purple50-to-white: 255 255 255;
    --color-purple80-to-white: 255 255 255;
    --color-neutral50-to-white: 255 255 255;
    --color-neutral50-to-dark20: 204 204 204;
    --color-purple60-to-white: 255 255 255;
    --color-neutral20-to-dark40: 102 102 102;
    /* --color-neutral20-to-dark70: ; */
    --color-red50-to-dark30: 77 77 77;
    --color-red50-to-dark10: 204 204 204;
    --color-red50-to-red50: 208 55 92;
    --color-green50-to-dark30: 77 77 77;
    --color-neutral40-to-dark00: 0 0 0;
    --color-neutral80-to-white: 255 255 255;
    --color-neutral20-to-dark50: 128 128 128;
    --color-white-to-dark50: 128 128 128;
    --color-white-to-dark80: 51 51 51;
    --color-white-to-neutral80: 25, 9, 58;
    --color-neutral80-to-dark10: 204 204 204;
    --color-neutral80-to-dark30: 77 77 77;
    --color-purple80-to-dark10: 204 204 204;
    --color-purple70-to-dark10: 204 204 204;
    --color-purple70-to-dark30: 77 77 77;
    --color-purple70-to-purple70: 42 16 96;
    --color-purple50-to-dark10: 204 204 204;
    --color-purple50-to-purple50: 85 31 193;
    --color-purple10-to-dark80: 51 51 51;
    --color-purple10-to-dark20: 51 51 51;
    --color-purple05-to-dark20: 51 51 51;
    --color-purple05-to-dark50: 128 128 128;
    --color-purple05-to-dark70: 77 77 77;
    --color-purple05-to-dark80: 51 51 51;
    --color-purple10-to-field-input-disable: 102 102 102;
    --color-purple10-to-dark10: 204 204 204;
    --color-purple10-to-dark50: 128 128 128;
    --color-purple20-to-dark80: 51 51 51;
    --color-neutral10-to-dark90: 26 26 26;
    /*  */
  }
}

/* .dark {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}

.dark .bg-transparent{
  background-color: var(--background-color-dark);
}

.dark .bg-white{
  background-color: var(--background-color-dark);
}

.dark .continue-course{
  background: var(--background-color-dark);
}

.dark .text-black{
  color: var(--text-color-dark);
}

.dark .text-purple-80{
  color: var(--text-color-dark);
}

.dark .text-neutral-60{
  color: var(--text-color-dark);
}

.dark .text-neutral-70{
  color: var(--text-color-dark);
}

.dark .text-neutral-80{
  color: var(--text-color-dark);
} */

html,
body {
  scroll-behavior: auto;
}

body {
  overflow-x: hidden;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #f2f2f3 #d3d2d7;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #f2f2f3;
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: #d3d2d7;
  border-radius: 14px;
  border: 3px solid #f2f2f3;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@layer components {
  .wrap-article {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: #000000;
  }

  .home-tenant>strong {
    font-weight: 900;
  }

  .home-tenant {
    margin-top: 8px;
  }

  .wrap-article p,
  ul,
  li,
  ol,
  h2,
  h3,
  blockquote {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: var(--color-black);
  }

  .wrap-article a {
    color: blue;
    text-decoration: underline;
    font-weight: 100;
  }

  .wrap-article>h2,
  .wrap-article>h3 {
    margin: 1rem 0;
    font-weight: 900;
  }

  .wrap-article figure {
    width: 100%;
    margin: 1rem 0;
  }

  .wrap-article iframe {
    width: -webkit-fill-available;
    height: 40vh;
  }

  .wrap-article blockquote {
    margin: 1rem 0;
    font-weight: 900;
    quotes: "\201C""\201D""\2018""\2019";
  }

  .wrap-article blockquote>p {
    display: inline;
  }

  .wrap-article ol {
    list-style: lower-alpha;
    margin-left: 2rem;
  }

  .wrap-article ul {
    list-style: square;
    margin-left: 1rem;
  }

  .wrap-article blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }

  .wrap-article ul>li {
    margin: 0 0 1rem 1rem;
  }

  .wrap-article ul>li:first-child {
    margin: 1rem 0 1rem 1rem;
  }

  .card-ads {
    min-width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .card-ads>ins {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wrap-html {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: var(--color-black);
  }

  .wrap-html b,
  .wrap-html strong {
    font-size: 14px;
    line-height: 20px;
  }

  .wrap-html ol {
    list-style: decimal;
    margin-left: 2rem;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
  }

  .wrap-html ul {
    list-style: square;
    margin-left: 1rem;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: var(--color-black);
  }

  .wrap-html>table {
    width: 100% !important;
  }

  .swiper {
    position: unset !important;
  }

  .swiper>.swiper-wrapper {
    display: flex;
    align-items: stretch;
  }

  /* swiper-home styling*/
  .swiper-home {
    overflow: hidden;
    z-index: 1;
    display: flex;
    justify-content: center;
  }

  .swiper-home>.swiper-pagination {
    display: flex;
  }

  .swiper-home>.swiper-pagination>.swiper-pagination-bullet {
    background: #f2f2f399;
    opacity: 0.5;
    height: 8px;
    width: 8px;
    margin: unset !important;
  }

  .swiper-home>.swiper-pagination>.swiper-pagination-bullet-active {
    background: white;
    opacity: 1;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .swiper-static-home {
    overflow: hidden;
    z-index: 1;
  }

  .swiper-static-home>.swiper-wrapper>.swiper-slide {
    display: flex;
    align-items: flex-end;
    width: auto;
    /* margin-right: 8px; */
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
    cursor: pointer;
  }

  .gkcard-container {
    z-index: 0;
  }

  .card-new {
    background: linear-gradient(1.56deg,
        #5c5c5c -6%,
        rgba(217, 217, 217, 0) 82.31%);
  }

  .paginate {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    cursor: pointer;
    margin: 1rem 0;
    gap: 0.5rem;
  }

  .paginate-item {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #bebcc4;
  }

  .paginate-icon {
    /* width: 24px;
    height: 24px; */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #3e3851;
    border: 2px solid #3e3851;
    border-radius: 33px;
  }

  .paginate-icon.disabled {
    color: #bebcc4;
    border: 2px solid #bebcc4;
  }

  .paginate-item.selected {
    color: #19093a;
    font-weight: bold;
  }

  .group-title-name {
    width: calc(200px - 44px);
  }

  /* .card-cover-detail {
    height: calc(0.5 * ((100vw - 0px) / 1));
  } */

  .wrap-image {
    position: relative;
    display: block;
    width: 100%;
    /* height: 0px; */
    max-width: 500px;
    /* padding-top: 33.3333%; */
    border-radius: 8px;
  }

  .bg-testimony {
    backdrop-filter: blur(8px);
    box-shadow: -3px 11px 41px 0px #9f9f9f26;
  }

  .swiper-home>.swiper-button-next,
  .swiper-home>.swiper-button-prev {
    display: none;
  }

  .swiper-customized>.swiper-button-next,
  .swiper-customized>.swiper-button-prev {
    background: #fff;
    width: 32px;
    height: 32px;
    padding: 20px;
    border: 1px solid #f2f2f399;
    /* box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3); */
    box-shadow: -3px 11px 41px 0px #9f9f9f26;
    backdrop-filter: blur(13px);
    border-radius: 100px;
    z-index: 2;
    top: 45%;
  }

  .swiper-customized>.swiper-button-next {
    right: -20px;
  }

  .swiper-customized>.swiper-button-prev {
    left: -20px;
  }

  .swiper-customized>.swiper-button-next::after,
  .swiper-customized>.swiper-button-prev::after {
    font-size: 16px;
    font-weight: bold;
    color: #551fc1;
  }

  .swiper-customized {
    overflow: hidden;
    /* overflow: visible !important; */
  }

  .swiper-customized-business>.swiper-button-next,
  .swiper-customized-business>.swiper-button-prev {
    background: #fff;
    width: 32px;
    height: 32px;
    /* border: 1px solid #f2f2f399; */
    /* box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3); */
    border-radius: 100px;
    z-index: 2;
    top: 50%;
  }

  .swiper-customized-business>.swiper-button-next {
    right: 6px;
  }

  .swiper-customized-business>.swiper-button-prev {
    left: 6px;
  }

  .swiper-customized-business>.swiper-button-next::after,
  .swiper-customized-business>.swiper-button-prev::after {
    font-size: 16px;
    font-weight: bold;
    color: #551fc1;
  }

  .swiper-customized-business {
    overflow: hidden;
  }

  @media screen and (min-width: 1366px) {
    .swiper-static-home {
      padding: unset;
    }

    .wrap-article {
      font-size: 16px;
      line-height: 24px;
    }

    .wrap-article p,
    ul,
    li,
    ol,
    h2,
    h3,
    blockquote {
      font-size: 16px;
      line-height: 24px;
    }

    /* .wrap-article > .full-width-image {
      width: 70%;
      margin: auto auto 1.5rem auto;
    } */

    .wrap-html {
      font-size: 16px;
      line-height: 24px;
      color: var(--color-black);
    }

    .wrap-html b,
    .wrap-html strong {
      font-size: 16px;
      line-height: 24px;
    }

    .wrap-html ol {
      font-size: 16px;
      line-height: 24px;
    }

    .wrap-html ul {
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 481px) {

    /* .card-product-new, */
    .card-static {
      width: calc(480px - 32px);
    }

    .card-cover-detail {
      height: 270px;
    }

    .card-image {
      height: 228px;
    }

    .swiper-static-home {
      padding: unset;
    }

    .wrap-article {
      font-size: 16px;
      line-height: 24px;
    }

    .wrap-article p,
    ul,
    li,
    ol,
    h2,
    h3,
    blockquote {
      font-size: 16px;
      line-height: 24px;
    }

    /* .wrap-article > .full-width-image {
      width: 70%;
      margin: auto auto 1.5rem auto;
    } */

    .wrap-html {
      font-size: 16px;
      line-height: 24px;
    }

    .wrap-html b,
    .wrap-html strong {
      font-size: 16px;
      line-height: 24px;
    }

    .wrap-html ol {
      font-size: 16px;
      line-height: 24px;
    }

    .wrap-html ul {
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media screen and (max-width: 768px) {
    .home-tenant {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 0px;
    }
  }

  @media screen and (max-width: 480px) {
    .swiper-customized {
      overflow-x: scroll !important;
    }

    /* .card-product-new */
    .card-static {
      width: calc(100vw - 32px);
    }

    .card-cover-detail {
      height: 200px;
    }

    .card-image {
      height: 185px;
    }

    .swiper-home>.swiper-pagination {
      width: 100% !important;
      bottom: 16px !important;
      /* gap: 8px !important; */
    }

    .swiper-static-home {
      padding: 0 16px;
    }

    .swiper-home>.swiper-pagination>.swiper-pagination-bullet {
      background: #f2f2f399;
      opacity: 0.5;
      height: 5px;
      width: 5px;
    }

    .swiper-home>.swiper-pagination>.swiper-pagination-bullet-active {
      background: white;
      opacity: 1;
      height: 6px;
      width: 6px;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  appearance: textfield;
  /* Firefox */
}

/* blinking effect */
.blink {
  animation: blinking 0.6s infinite;
}

@keyframes blinking {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

/*  */
.video-js {
  width: 100% !important;
  height: 100% !important;
  background-color: unset !important;
}

.card-course {
  transition: transform 250ms ease-out;
}

.card-course:hover {
  transform: scale(1.1);
  position: absolute;
  z-index: 999;
  width: 285px;
}

.floating-wrapper {
  position: fixed;
  visibility: hidden;
  inset: 0px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin: 0px;
  padding: 0px;
  z-index: 999;
}

.floating-open {
  position: fixed;
  visibility: visible;
  /* cursor: pointer; */
  border: none;
  background-color: transparent;
  padding: 0px;
  margin: 0px;
  left: 500px;
  transition: all 0.5s ease 0s;
  height: auto;
  display: flex;
  flex-direction: row;
}

.floating-message {
  position: fixed;
  transition: all 0.5s ease 0s;
  z-index: 2000;
  /* min-height: 368px; */
  transition: all 0.5s ease 0s;
  display: flex;
}

.floating-content {
  display: flex;
  gap: 16px;
  /* padding: 24px; */
  border-radius: 0 8px 8px 0;
  background: white;
  box-sizing: border-box;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.floating-action {
  display: flex;
  align-items: center;
  width: fit-content;
}

.floating-label {
  background: #817e8c;
  color: white;
  padding: 12px 4px;
  position: relative;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  z-index: 1;
  writing-mode: vertical-rl;
  transform: rotateZ(180deg);
  border-radius: 8px 0 0 8px;
}

.floating-label:hover {
  background: #5d586c;
}

/* Timer Circle Class */
#circle-timer {
  position: relative;
  height: 100px;
  width: 100px;
  text-align: center;
  margin: auto;
}

#circle-timer-number {
  color: white;
  display: inline-block;
  line-height: 40px;
}

#circle-timer>svg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
  transform: rotateZ(-30deg);
}

#circle-timer>svg circle {
  stroke-dasharray: 100px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 5px;
  stroke: #551fc1;
  fill: none;
  animation: countdown 10s infinite;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }

  to {
    stroke-dashoffset: 100px;
  }
}

.floating-button {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 8px #999;
  z-index: 100;
}

.invisible-scroll::-webkit-scrollbar {
  width: 1px;
}

.invisible-scroll::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 1px;
}

.invisible-scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 1px;
  border: 1px solid transparent;
}

.bg-image-testimony {
  background-image: linear-gradient(135deg,
      #eadedb 0%,
      #fef4f0 50%,
      #eee9f9 100%);
  border-radius: 0px 150px 150px 0px;
}

/* LMS pages grid styles */

.container-lms {
  display: grid;
  grid-template-areas:
    "banner banner banner"
    "main main sidebar"
    "info info sidebar"
    "tabs tabs sidebar";
  gap: 16px;
  /* max-width: 1366px;
  width: 95%; */
  /* max-width: 1440px; */
  width: 97%;
  margin: 0 auto;
  grid-template-columns: 37.5% 37.5% auto;
  grid-template-rows: auto auto auto auto;
  padding-top: 8px;
}

.main-content {
  grid-area: main;
  aspect-ratio: 16/9;
}

.banner-content {
  grid-area: banner;
}

.info-content {
  grid-area: info;
}

.tabs-content {
  grid-area: tabs;
  display: hidden;
}

.sidebar-content {
  grid-area: sidebar;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.vds-blocker {
  position: relative !important;
}

.plyr__control--overlaid {
  background: #551fc1 !important;
}

.plyr .plyr__slider {
  color: #551fc1 !important;
}

.plyr__controls .plyr__controls__item:hover {
  background: transparent !important;
}

.plyr button,
.plyr [role="button"]:hover {
  background: transparent !important;
}

/* The switch - the box around the slider */
.normal_switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.normal_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.normal_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.normal_slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.normal_slider {
  background-color: #551fc1;
}

input:focus+.normal_slider {
  box-shadow: 0 0 1px #551fc1;
}

/* Transform the slider */
input:checked+.normal_slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Firefox only */
@-moz-document url-prefix() {
  iframe.vds-youtube[data-no-controls] {
    width: 100000%;
  }
}

.powered-by-ai {
  background: linear-gradient(90deg, #f37a41 0%, #551fc1 100%);
  /* you can change the colors based on your preference */
  background-clip: text;
  /*add this line, it defines how far the background should extend within an element, here we set it to text */
  -webkit-background-clip: text;
  /* add this line, for browsers compatibility */
  -webkit-text-fill-color: transparent;
}

.dark .powered-by-ai {
  background: unset;
  /* you can change the colors based on your preference */
  background-clip: unset;
  /*add this line, it defines how far the background should extend within an element, here we set it to text */
  -webkit-background-clip: unset;
  /* add this line, for browsers compatibility */
  -webkit-text-fill-color: unset;
  color: white;
}

.continue-course {
  background: linear-gradient(180deg, #ffffff 0%, #eee9f9 100%);
}

.dark .continue-course {
  background: linear-gradient(180deg, #000000 0%, #2a1060b8 72%);
}

:where([data-media-provider] video),
:where([data-media-provider] iframe) {
  aspect-ratio: 16/9 !important;
}

/* ============= */

/* iframe.vds-youtube[data-no-controls] {
  height: 588px !important;
  width: 100000%;
} */
/* ============= */

/* @media only screen and (min-width: 1365px) {
  .sidebar-content {
    top: -478px;
  }
}

@media only screen and (min-width: 1920px) {
  .sidebar-content {
    top: -520px;
  }
} */

/* @media only screen and (max-width: 1366px) {
  .container-lms {
    grid-template-rows: auto 555px auto auto;
  }
} */

/* sg-max */
@media only screen and (max-width: 768px) {
  .container-lms {
    /* hide sidebar content area in mobile view */
    grid-template-areas:
      "banner"
      "main"
      "info"
      "tabs";
    gap: 16px;
    width: 100%;
    grid-template-columns: 100%;
    padding-top: unset;
    /* Hide gap of 16px on mobile */
    margin-top: -16px;
    grid-template-rows: auto auto auto auto;
  }

  .main-content {
    position: sticky;
    top: 72px;
    z-index: 8;
  }

  .sidebar-content {
    display: none;
    border: unset;
    border-radius: unset;
    position: relative;
    top: unset;
  }

  .tabs-content {
    display: flex;
    width: 100%;
  }

  .tabs-content>div:first-child {
    width: 100%;
  }
}

.vidstack-provider {
  position: absolute;
  inset: 0;
  /* z-index: 1;
  width: 100%;
  height: auto;
  aspect-ratio: inherit;
  pointer-events: auto;
  border-radius: inherit; */
  /* display: none; */
}

.vidstack-player[data-ended] .vidstack-provider {
  display: block;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: black;
}

.vidstack-player[data-media-player][data-layout="plyr"]:not([data-fullscreen]) {
  border-radius: 0px !important;
}

.player {
  --brand-color: #f5f5f5;
  --focus-color: #4e9cf6;

  --audio-brand: var(--brand-color);
  --audio-focus-ring-color: var(--focus-color);
  --audio-border-radius: 2px;

  --video-brand: var(--brand-color);
  --video-focus-ring-color: var(--focus-color);
  --video-border: unset;
  border-radius: 10px;
  /* 👉 https://vidstack.io/docs/player/components/layouts/default#css-variables for more. */
}

.player[data-view-type="audio"] .vds-poster {
  display: none;
}

.player[data-view-type="video"] {
  aspect-ratio: 16 /9;
  border: unset !important;
  border-radius: unset !important;
  height: 100%;
}

.vds-provider {
  position: absolute;
  inset: 0;
  /* z-index: 1;
  width: 100%;
  height: auto;
  aspect-ratio: inherit;
  pointer-events: auto;
  border-radius: inherit; */
  /* display: none; */
}

.player[data-ended] .vds-provider {
  display: block;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: black;
}

:where(.vds-poster) {
  height: 100% !important;
  object-fit: cover !important;
}

div[data-ended] .vid-end {
  z-index: 11;
}

[data-media-player][data-layout="video"] {
  border: unset !important;
}

ol.upper-alpha {
  list-style-type: upper-alpha;
}

ol.lower-alpha {
  list-style-type: lower-alpha;
}

/* hide temp */
/* .vds-video-layout .vds-load-container .vds-play-button {
  background-color: #0000004f;
}

.vds-video-layout .vds-load-container .vds-play-button svg {
  color: #fff;
} */
/* --------------------- */

/* LOADER FADE IN OUT */

.loader-fade-in-out,
.loader-fade-in-out:before,
.loader-fade-in-out:after {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  animation-fill-mode: both;
  animation: bblFadInOut 1.6s infinite ease-in-out;
}

.loader-fade-in-out {
  font-size: 8px;
  color: #f37a41;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
  position: absolute;
  top: -10px;
}

.loader-fade-in-out:before,
.loader-fade-in-out:after {
  content: "";
  position: absolute;
  top: 0;
  color: #f37a41;
}

.loader-fade-in-out:before {
  left: -14px;
  animation-delay: -0.32s;
}

.loader-fade-in-out:after {
  left: 14px;
}

@keyframes bblFadInOut {

  0%,
  80%,
  100% {
    box-shadow: 0 20px 0 -1.3em;
  }

  40% {
    box-shadow: 0 20px 0 0;
  }
}

/*  */

/* autocomplete */

.autocomplete-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
}

.autocomplete-wrapper>div {
  width: 100%;
}

.autocomplete-wrapper input {
  /* border: 1px solid #BEBCC4; */
  font-size: 14px;
  /* padding: 14px 16px; */
  /* border-radius: 4px; */
  /* border-bottom: 1px solid #BEBCC4; */
}

.autocomplete-wrapper input:focus {
  box-shadow: none;
  outline: none;
}

.autocomplete-wrapper .dropdown {
  width: 100%;
  padding: 0;
  text-align: left;
  max-height: 90vh;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  /* border: 1px solid #0F67FF; */
  border-top: none;
  display: grid;
  /* gap: 8px; */
}

.dark .autocomplete-wrapper .dropdown {
  background-color: #000000;
}

.autocomplete-wrapper .item {
  display: block;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid #d3d2d7;
  border-radius: 8px;
  padding: 8px;
  margin-top: 8px;
}

.dark .autocomplete-wrapper .item {
  border: 1px solid #373737;
}

.autocomplete-wrapper .item.selected-item {
  background-color: #f6f3fd;
  color: #551fc1;
  position: relative;
}

/*

.autocomplete-wrapper .item:hover {
  background-color: #F6F3FD;
  color: #551FC1;
} */

.autocomplete-wrapper-desktop {
  width: 100%;
  position: relative;
  display: inline-block;
}

.autocomplete-wrapper-desktop>div {
  width: 100%;
}

.autocomplete-wrapper-desktop input {
  /* border: 1px solid #cecece;
  padding: 12px 15px;
  font-size: 14px;
  width: 100%; */
  font-size: 14px;
}

.autocomplete-wrapper-desktop input:focus {
  box-shadow: none;
  outline: none;
}

.autocomplete-wrapper-desktop .dropdown {
  /* width: 100%;
  padding: 0;
  text-align: left;
  max-height: 280px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #551FC1;
  border-top: none; */

  width: 100%;
  padding: 0;
  text-align: left;
  /* max-height: 90vh; */
  max-height: 280px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  /* border: 1px solid #0F67FF; */
  border-top: none;
  display: grid;
  /* gap: 8px; */
}

.dark .autocomplete-wrapper-desktop .dropdown {
  background-color: #000000;
}

.autocomplete-wrapper-desktop .item {
  display: block;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid #d3d2d7;
  border-radius: 8px;
  padding: 8px;
  margin-top: 8px;
}

.dark .autocomplete-wrapper-desktop .item {
  border: 1px solid #373737;
}

.autocomplete-wrapper-desktop .item.selected-item {
  background-color: #f6f3fd;
  color: #551fc1;
  position: relative;
}

/* 

.autocomplete-wrapper-desktop .item:hover {
  background-color: #0069ff;
  color: #FAFBFC;
} */

/*  */

.vjs-download-button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.vjs-download-button svg {
  fill: white;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

@media (max-width: 600px) {
  .vjs-download-button svg {
    width: 12px;
    height: 12px;
  }
}

/* Mouse Scroll Animation */

.scroll-downs {
  margin: auto;
  width: 34px;
  height: 55px;
}

.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #000;
  border-radius: 25px;
  opacity: 0.5;
  box-sizing: content-box;
}

.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #000;
  opacity: 0.5;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}

@media screen and (max-width: 768px) {
  .mousey {
    width: 2px;
    height: 25px;
  }
}

@keyframes scroll {
  0% {
    opacity: 0;
  }

  10% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-out {
  animation: slideOut 0.5s ease-out forwards;
}

.slide-in {
  animation: slideIn 0.5s ease-out forwards;
}

@keyframes fill-purple-50 {
  0% {
    width: 0;
    background-color: #551fc1;
    /* bg-purple-50 */
  }

  100% {
    width: 100%;
    background-color: #551fc1;
    /* bg-purple-50 */
  }
}

@keyframes fill-purple-10 {
  0% {
    width: 0;
    background-color: #eee9f9;
    /* bg-purple-10 */
  }

  100% {
    width: 100%;
    background-color: #eee9f9;
    /* bg-purple-10 */
  }
}

.animate-fill-purple-50 {
  animation: fill-purple-50 0.5s forwards;
}

.animate-fill-purple-10 {
  animation: fill-purple-10 0.5s forwards;
}

@keyframes exit-fill-purple-50 {
  0% {
    width: 100%;
    background-color: #551fc1;
    /* bg-purple-50 */
  }

  100% {
    width: 0;
    background-color: #551fc1;
    /* bg-purple-50 */
    left: 100%;
  }
}

@keyframes exit-fill-purple-10 {
  0% {
    width: 100%;
    background-color: #eee9f9;
    /* bg-purple-10 */
  }

  100% {
    width: 0;
    background-color: #eee9f9;
    /* bg-purple-10 */
    left: 100%;
  }
}

.animate-exit {
  animation: exit-fill-purple-50 0.5s forwards;
}

.margin-course-avatar {
  margin-left: 28px;
}

.dynamic-width {
  width: 95.6vw;
}

.dynamic-width-2 {
  width: 96.7vw;
}

.sidebar-dynamic-height {
  height: calc(100vh - 234px);
}

@media (max-width: 1440px) {
  .dynamic-width {
    width: 96.4vw;
  }

  .dynamic-width-2 {
    width: 97.3vw;
  }

  .sidebar-dynamic-height {
    height: calc(100vh - 234px);
  }
}

@media (max-width: 1280px) {
  .margin-course-avatar {
    margin-left: 8px;
  }

  .dynamic-width {
    width: 99vw;
  }

  .dynamic-width-2 {
    width: 97.3vw;
  }

  .sidebar-dynamic-height {
    height: calc(100vh - 234px);
  }
}